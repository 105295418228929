import Vue from 'vue'
import Router from 'vue-router'




Vue.use(Router)
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location){
  return originalPush.call(this,location).catch(err=>err);
}
export default new Router({
  routes: [
    {
      path: '/debug',
      name: 'Debug',
      component: resolve => { 
        return require(['@/components/Debug'],resolve)
      }
    },
    {
      path: '/',
      name: 'Home',
      component: resolve => { 
        return require(['@/pages/Home/Home'],resolve)
      }
    },
    {
      path: '/home',
      name: 'Home',
      component: resolve => { 
        return require(['@/pages/Home/Home'],resolve)
      }
    },
    {
      path: '/portalsliceslisting',
      name: 'PortalSlicesListing',
      component: resolve => { 
        return require(['@/pages/PortalSlicesListing/PortalSlicesListing'],resolve)
      }
    },
    {
      path: '/wishlist',
      name: 'Wishlist',
      component: resolve => { 
        return require(['@/pages/wishlist/wishlist'],resolve)
      }
    },
    {
      path: '/checkout',
      name: 'Checkout',
      component: resolve => { 
        return require(['@/pages/Checkout/Checkout'],resolve)
      }
    },
    {
      path: '/shopping/:id',
      name: 'Shopping',
      component: resolve => { 
        return require(['@/pages/Shopping/Shopping'],resolve)
      }
    },
    {
      path: '/ad',
      name: 'Ad',
      component: resolve => { 
        return require(['@/pages/ad/ad'],resolve)
      },
      children:[ 
        {
          path: '/',
          name: '',
          component: resolve => { 
            return require(['@/pages/ad/ShippingAddress/ShippingAddress'],resolve)
          }
        },
        {
          path: 'shippingaddress',
          name: 'ShippingAddress',
          component: resolve => { 
            return require(['@/pages/ad/ShippingAddress/ShippingAddress'],resolve)
          }
        },
        {
          path: 'payment/:id',
          name: 'Payment',
          component: resolve => { 
            return require(['@/pages/ad/Payment/Payment'],resolve)
          }
        },
        {
          path: 'review/:id/:paymentid',
          name: 'Review',
          component: resolve => { 
            return require(['@/pages/ad/review/review'],resolve)
          }
        },
        {
          path: 'detail/:order',
          name: 'Detail',
          component: resolve => { 
            return require(['@/pages/ad/Detail/Detail'],resolve)
          }
        }
      ]
    },
    {
      path: '/useraccount',
      name: 'UserAccount',
      component: resolve => { 
        return require(['@/pages/UserAccount/UserAccount'],resolve)
      },
      children:[ 
        {
          path: '/',
          name: '',
          component: resolve => { 
            return require(['@/pages/UserAccount/orders/orders'],resolve)
          }
        },
        {
          path: 'orders',
          name: 'Orders',
          component: resolve => { 
            return require(['@/pages/UserAccount/orders/orders'],resolve)
          }
        },{
          path: 'commsPreferences',
          name: 'CommsPreferences',
          component: resolve => { 
            return require(['@/pages/UserAccount/comms-preferences/comms-preferences'],resolve)
          }
        },{
          path: 'connectedServices',
          name: 'ConnectedServices',
          component: resolve => { 
            return require(['@/pages/UserAccount/connected-services/connected-services'],resolve)
          }
        },{
          path: 'credits',
          name: 'Credits',
          component: resolve => { 
            return require(['@/pages/UserAccount/credits/credits'],resolve)
          }
        },{
          path: 'details',
          name: 'Details',
          component: resolve => { 
            return require(['@/pages/UserAccount/details/details'],resolve)
          }
        },{
          path: 'address',
          name: 'Address',
          component: resolve => { 
            return require(['@/pages/UserAccount/address/address'],resolve)
          }
        }

      ]
    },
    {
      path: '/obligationorderdetail/:order',
      name: 'ObligationOrderDetail',
      component: resolve => { 
        return require(['@/pages/ObligationOrderDetail/ObligationOrderDetail'],resolve)
      }
    },
    {
      path: '/ordersdetail/:order',
      name: 'OrdersDetail',
      component: resolve => { 
        return require(['@/pages/OrderDetail/OrderDetail'],resolve)
      }
    },
    {
      path: '/refundOrderdetail/:order',
      name: 'RefundOrderDetail',
      component: resolve => { 
        return require(['@/pages/RefundOrderDetail/RefundOrderDetail'],resolve)
      }
    },
    {
      path: '/agreement',
      name: 'Agreement',
      component: resolve => { 
        return require(['@/pages/agreement/agreement'],resolve)
      },
      children:[ 
        {
          path: '/',
          name: '',
          component: resolve => { 
            return require(['@/pages/agreement/contact/contact'],resolve)
          }
        },
        {
          path: 'contact',
          name: 'Contact',
          component: resolve => { 
            return require(['@/pages/agreement/contact/contact'],resolve)
          }
        },
        {
          path: 'faqs',
          name: 'Faqs',
          component: resolve => { 
            return require(['@/pages/agreement/faqs/faqs'],resolve)
          }
        },
        {
          path: 'orders-and-shipping',
          name: 'OrdersAndShipping',
          component: resolve => { 
            return require(['@/pages/agreement/orders-and-shipping/orders-and-shipping'],resolve)
          }
        },
        {
          path: 'returns-and-refunds',
          name: 'ReturnsAndRefunds',
          component: resolve => { 
            return require(['@/pages/agreement/returns-and-refunds/returns-and-refunds'],resolve)
          }
        },
        {
          path: 'payment-and-pricing',
          name: 'PaymentAndPricing',
          component: resolve => { 
            return require(['@/pages/agreement/payment-and-pricing/payment-and-pricing'],resolve)
          }
        },
        {
          path: 'how-to-shop',
          name: 'howToShop',
          component: resolve => { 
            return require(['@/pages/agreement/how-to-shop/how-to-shop'],resolve)
          }
        },
        {
          path: 'privacy-policy',
          name: 'PrivacyPolicy',
          component: resolve => { 
            return require(['@/pages/agreement/privacy-policy/privacy-policy'],resolve)
          }
        },
        {
          path: 'terms-and-conditions',
          name: 'TermsAndConditions',
          component: resolve => { 
            return require(['@/pages/agreement/terms-and-conditions/terms-and-conditions'],resolve)
          }
        },
        {
          path: 'farfetch-accessibility',
          name: 'FarfetchAccessibility',
          component: resolve => { 
            return require(['@/pages/agreement/farfetch-accessibility/farfetch-accessibility'],resolve)
          }
        },
        {
          path: 'protection-of-intellectual-property',
          name: 'ProtectionOfIntellectualProperty',
          component: resolve => { 
            return require(['@/pages/agreement/protection-of-intellectual-property/protection-of-intellectual-property'],resolve)
          }
        },
        {
          path: 'personal-sensitive-information-policy',
          name: 'PersonalSensitiveInformationPolicy',
          component: resolve => { 
            return require(['@/pages/agreement/personal-sensitive-information-policy/personal-sensitive-information-policy'],resolve)
          }
        }
        
        
        
      ]
    }

    




    
    
  ],
  scrollBehavior(to, from, savePosition) {
    // return 期望滚动到哪个的位置
    console.log(to,from,savePosition);
    return {
      y: 0,
    };
  }
})
