<template>
    <div class="footer">
        <!-- <NewsletterFooter/> -->
        <MainAreaFooter/>
    </div>
</template>
  
<script>
// import NewsletterFooter from './NewsletterFooter/NewsletterFooter.vue'
import MainAreaFooter from './MainAreaFooter/MainAreaFooter.vue'

  export default {
    name: "FooterPage",
    components:{
      // NewsletterFooter,
      MainAreaFooter
    }
  };
</script>
  
<style scoped>

</style>