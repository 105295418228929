import observable from "@/services/observable.js";
export default {
    data() {
        return{
        }
    },
    mounted(){   
    },
    computed : {
        observable() {
            return observable;
        }
    },
    methods: {
        getCartCount(){
            this.http.get('/api/front/cart/count?numType=true&type=total').then(response => {
                console.log(response);
                if(response.data){
                    observable.CartCount = response.data.count;
                }
            })
        },
        getWishlistCount(){
            this.http.get('/api/front/collect/user?limit=1&page=1').then(response => {
                console.log(response);
                if(response.data){
                    observable.WishlistCount = response.data.total;
                }
            })
        },
        getTokenState(openLogin = true){
            if(!localStorage.getItem('LOGIN_STATUS_TOKEN')){
                if(openLogin){
                    observable.loginVisible = true
                }
                return true;
            }
            return false;
        }
    }
}