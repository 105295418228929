<template>
  <div id="app"> 
    <Login :visible ="observable.loginVisible" @loginClose="observable.loginVisible = false;"/>
    <Header/>
    <div class="router">
      <router-view/>
    </div>
    <Footer/>
    <CustomerService/>
  </div>
</template>

<script>  
import Header from "@/components/Header/Header.vue";
import Footer from "@/components/Footer/Footer.vue";
import Login from "@/components/Login/Login.vue";
import CustomerService from "@/components/CustomerService/CustomerService.vue";

import axios from 'axios';
export default {
  name: 'App',
  data(){
    return {
      window : window
    }
  },
  components: { 
    Header,
    Footer,
    Login,
    CustomerService
  },
  created(){
    if(localStorage.getItem('LOGIN_STATUS_TOKEN')){
      this.http.get('/api/front/islogin').then(response => { 
          if(response.code != 200){
            localStorage.clear()    
          }
      })
    }
    if(!localStorage.getItem('LOGIN_STATUS_TOKEN')){
      localStorage.clear()
    }
    this.getCartCount()
    this.getWishlistCount()
    console.log(this.cartCount);
    //获取国家位置
    // navigator.geolocation.getCurrentPosition(position => {
    //   const countryCode = this.getCountryCodeFromPosition(position);
    //   this.switchLanguageByCountry(countryCode);
    // },
    // error => {
    //   console.error('Error getting geolocation:', error);
    // });
  },
  mounted(){
     
  },
  methods:{
    getCountryCodeFromPosition(position) {
      console.log(position);
       

      const latitude = position.coords.latitude; // 根据 getCurrentPosition 获取的纬度
      const longitude = position.coords.longitude; // 根据 getCurrentPosition 获取的经度
      const apiKey = 'AIzaSyAL3GIY3a4E7oI-bpEdXbn9B82fcpot_vk';

      const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

      axios.get(url)
        .then(response => {
          const results = response.data.results;
          if (results.length > 0) {
            // 解析国家代码
            console.log(results);
            // const countryCode = extractCountryCodeFromResults(results);
            console.log('Country Code:', countryCode);
            // 在此处继续根据国家代码切换语言
          } else {
            console.error('No results found.');
          }
        })
        .catch(error => {
          console.error('Error fetching geolocation:', error);
        });



      const countryCode = 'us'; // 假设国家代码是美国
      return countryCode;
    },
    switchLanguageByCountry(countryCode) {
      // 根据国家代码切换语言
      switch (countryCode) {
        case 'us':
          this.$i18n.locale = 'en';
          break;
        case 'fr':
          this.$i18n.locale = 'fr';
          break;
      }
    }
  }
}
</script>

<style>
html{
  height: 100%;
  width: 100%;
}
body{
  margin: 0;
  padding: 0;
  height: 100%; /* 确保body元素的高度占满整个视口 */
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

  @media screen and (max-width: 768px) {
    /* 手机屏幕样式 */ 
    .pc{
      display: none;
    }
    .router{
       min-height: 400px;
      margin: 20px;height : calc(100% - 101px);
      padding-top: 40px;
    }
  }
    
  @media screen and (min-width: 769px) {
    /* 平板或电脑屏幕样式 */ 
    .phone{
      display: none;
    }
    .router{
      min-height: 550px;
      margin: 40px;height : calc(100% - 101px);
      padding-top: 70px;
    }
  }
  @media screen and (min-width: 1600px) {
    /* 平板或电脑屏幕样式 */ 
    .router{
      min-height: 700px;
      padding-left: 150px;
      padding-right: 150px;
    }
  }
  
.full{
  width: 100%;
  height: 100%;
}
.full-w{
  width: 100%; 
}
.full-h{
  height: 100%;
}
 
</style>

