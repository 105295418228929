<template>
    <div class="login"> 
        <t-dialog class="login_dlg login_dialog" :header="$t('welcome')" :footer="false" :visible="visible" :onClose="onClose">
            <t-tabs v-model="typeValue">
                <t-tab-panel value="login" :label="$t('login')" :destroyOnHide="false">
                    <div class="input_title" v-if="loginType == 'mail'">{{ $t('mail') }}</div>
                    <div class="subscribe">
                        <t-input v-if="loginType == 'mail'" v-model="mail" :placeholder="$t('inputMail')" />
                    </div>
                    <div class="input_title" v-if="loginType == 'phone'">{{ $t('phone') }}</div>
                    <div v-if="loginType == 'phone'" class="subscribe" style="display: flex;">
                    <EPhone :value.sync="phone"/>
                    </div> 
                    <!-- <a v-if="loginType == 'mail'" @click="loginType = 'phone';captchaTime = 60;captcha = ''">{{ $t('phone')+ $t('login') }}</a>
                    <a v-if="loginType == 'phone'" @click="loginType = 'mail';captchaTime = 60;captcha = ''">{{ $t('mail')+ $t('login') }}</a> -->

                    <div class="input_title">
                        <t-space>
                            <div style="line-height: 40px;">{{ $t('captcha')}}</div>
                        </t-space>
                    </div>
                    <div class="subscribe">
                        <t-space>
                            <t-input v-model="captcha" :placeholder="$t('inputCaptcha')"/>
                            <t-button style="height: 40px;background-color: black !important;" disabled v-if="captchaTime < 60">({{ captchaTime }}s)</t-button>
                            <t-button style="height: 40px;background-color: black !important;"  v-if="captchaTime == 60" @click="getSendCode()">{{ $t('getCaptcha') }}</t-button>
                        </t-space>
                    </div>
                    <!-- <div class="input_title">密码</div>
                    <div class="subscribe">
                        <t-input type="password" v-model="loginData.password" placeholder="请输入密码" />
                    </div> -->
                    <!-- <div style="display: flex;margin-top: 10px;">
                        <t-checkbox v-model="ispassword">{{ $t('components_login.ispassword') }}</t-checkbox>
                    </div> -->
                    <!-- <a>忘记密码了？</a> -->
                    <t-button class="e-button" block variant="base" @click="login">{{ $t('login') }}</t-button>
                </t-tab-panel>
                <t-tab-panel value="register" :label="$t('register')" :destroyOnHide="false">
                    <div class="input_title" v-if="loginType == 'mail'">{{ $t('mail') }}</div>
                    <div class="subscribe" v-if="loginType == 'mail'">
                        <t-input v-model="mail" :placeholder="$t('inputMail')"/>
                    </div>
                    <div class="input_title" v-if="loginType == 'phone'">{{ $t('phone') }}</div>
                    <div v-if="loginType == 'phone'" class="subscribe" style="display: flex;">
                        <EPhone :value.sync="phone"/>
                    </div> 
                    <!-- <a v-if="loginType == 'mail'" @click="loginType = 'phone';captchaTime = 60;captcha = ''">{{ $t('phone')+ $t('login') }}</a>
                    <a v-if="loginType == 'phone'" @click="loginType = 'mail';captchaTime = 60;captcha = ''">{{ $t('mail')+ $t('login') }}</a> -->

                    <div class="input_title">
                        <t-space>
                            <div style="line-height: 40px;">{{ $t('captcha')}}</div>
                        </t-space>
                    </div>
                    <div class="subscribe">
                        <t-space>
                            <t-input v-model="captcha" :placeholder="$t('inputCaptcha')" />
                            <t-button style="height: 40px;background-color: black !important;;" disabled v-if="captchaTime < 60">({{ captchaTime }}s)</t-button>
                            <t-button style="height: 40px;background-color: black !important;"  v-if="captchaTime == 60" @click="getSendCode()">{{ $t('getCaptcha') }}</t-button>
                        </t-space>
                    </div>
                    <!-- <div class="input_title">密码</div>
                    <div class="subscribe">
                        <t-input type="password" v-model="registerData.password"/>
                    </div> -->
                    <div style="display: flex;margin: 10px 0;">
                        <t-checkbox v-model="isagreement" :checked="true"></t-checkbox>
                        {{ $t('components_login.isagreement') }}
                    </div>
                    <div style="height: 100px;overflow:auto;">
                        {{ $t('components_login.agreement') }}
                    </div>
                    <t-button class="e-button" block variant="base" @click="login">{{ $t('register') }}</t-button>
                </t-tab-panel>

            </t-tabs>
        </t-dialog>

        <t-drawer class="login_dlg login_drawer" :visible="visible" :header="$t('welcome')" :footer="false" :onClose="onClose" :closeBtn="true" size="100%">
            <t-tabs v-model="typeValue" style="padding-top: 20px;">
                <t-tab-panel value="login" :label="$t('login')" :destroyOnHide="false">
                    <div class="input_title" v-if="loginType == 'mail'">{{ $t('mail') }}</div>
                    <div class="subscribe">
                        <t-input v-if="loginType == 'mail'" v-model="mail" :placeholder="$t('inputMail')" />
                    </div>
                    <div class="input_title" v-if="loginType == 'phone'">{{ $t('phone') }}</div>
                    <div v-if="loginType == 'phone'" class="subscribe" style="display: flex;">
                    <EPhone :value.sync="phone"/>
                    </div>
                    <!-- <a v-if="loginType == 'mail'" @click="loginType = 'phone';captchaTime = 60;captcha = ''">{{ $t('phone')+ $t('login') }}</a>
                    <a v-if="loginType == 'phone'" @click="loginType = 'mail';captchaTime = 60;captcha = ''">{{ $t('mail')+ $t('login') }}</a> -->

                    <div class="input_title">
                        <div style="line-height: 40px;">{{ $t('captcha')}}</div>
                    </div>
                    <div class="subscribe">
                        <t-space>
                            <t-input v-model="captcha" :placeholder="$t('inputCaptcha')" />
                            <t-button style="height: 40px;background-color: black;" disabled v-if="captchaTime < 60">({{ captchaTime }}s)</t-button>
                            <t-button style="height: 40px;"  v-if="captchaTime == 60" @click="getSendCode()">{{ $t('getCaptcha') }}</t-button>
                        </t-space>
                    </div>
                    <!-- <div class="input_title">密码</div>
                    <div class="subscribe">
                        <t-input type="password" v-model="loginData.password" placeholder="请输入密码" />
                    </div> -->
                    <!-- <div style="display: flex;margin-top: 10px;">
                        <t-checkbox v-model="ispassword">{{ $t('components_login.ispassword') }}</t-checkbox>
                    </div> -->
                    <!-- <a>忘记密码了？</a> -->
                    <t-button class="e-button" block variant="base" @click="login">{{ $t('login') }}</t-button>
                </t-tab-panel>
                <t-tab-panel value="register" :label="$t('register')" :destroyOnHide="false">
                    <div class="input_title" v-if="loginType == 'mail'">{{ $t('mail') }}</div>
                    <div class="subscribe" v-if="loginType == 'mail'">
                        <t-input v-model="mail" :placeholder="$t('inputMail')"/>
                    </div>
                    <div class="input_title" v-if="loginType == 'phone'">{{ $t('phone') }}</div>
                    <div v-if="loginType == 'phone'" class="subscribe" style="display: flex;">
                        <EPhone :value.sync="phone"/>
                    </div>
                    <!-- <a v-if="loginType == 'mail'" @click="loginType = 'phone';captchaTime = 60;captcha = ''">{{ $t('phone')+ $t('login') }}</a>
                    <a v-if="loginType == 'phone'" @click="loginType = 'mail';captchaTime = 60;captcha = ''">{{ $t('mail')+ $t('login') }}</a> -->

                    <div class="input_title">
                        <div style="line-height: 40px;">{{ $t('captcha')}}</div>
                    </div>

                    <div class="subscribe">
                        <t-space>
                            <t-input v-model="captcha" :placeholder="$t('inputCaptcha')" />
                            <t-button style="height: 40px;background-color: black; !important" disabled v-if="captchaTime < 60">({{ captchaTime }}s)</t-button>
                            <t-button style="height: 40px;background-color: black; !important"  v-if="captchaTime == 60" @click="getSendCode()">{{ $t('getCaptcha') }}</t-button>
                        </t-space>
                    </div>
                    <!-- <div class="input_title">密码</div>
                    <div class="subscribe">
                        <t-input type="password" v-model="registerData.password"/>
                    </div> -->
                    <div style="display: flex;margin: 10px 0;">
                        <t-checkbox v-model="isagreement" :checked="true"></t-checkbox>
                        {{ $t('components_login.isagreement') }}
                    </div>
                    <div style="height: 100px;overflow:auto;">
                        {{ $t('components_login.agreement') }}
                    </div>
                    <t-button class="e-button" block variant="base" @click="login">{{ $t('register') }}</t-button>
                </t-tab-panel>

            </t-tabs>
        </t-drawer>
    </div>
</template>
  
<script>
import EPhone from "@/components/e-phone/e-phone.vue"
export default {
    name: 'LoginPage',
    data() {
        return {
            optionsData: [],
            typeValue: 'login',
            loginType: 'mail',
            ispassword: true,
            isagreement: true,
            mail: '',   
            encode: '',
            phone: '',
            password: '',
            captchaTime : 60,
            captcha : '',
        }
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    components: {
        EPhone
    },
    methods:{ 
        onClose(){ 
            this.$emit('loginClose', false)
        },
        login(){
            if(this.loginType == 'mail'){
                if(this.mail == ''){
                    this.messagePlugin.error(this.$t('inputMail'))
                    return
                }
                const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
                if(!mailReg.test(this.mail)){
                    this.messagePlugin.error(this.$t('inputErrorMail'))
                    return
                } 
            }else{
                if(this.phone == ''){
                    this.messagePlugin.error(this.$t('inputPhone'))
                    return
                }
            }
            if( this.typeValue == 'register' ){
                
                if(!this.isagreement){
                    this.messagePlugin.error(this.$t('components_login.errorAgreement'))
                    return
                }
                
            }
            if(this.captcha == ''){
                this.messagePlugin.error(this.$t('inputCaptcha') )
                return
            }
            if(this.loginType == 'mail'){
                this.http.post('/api/front/login/email',{
                    "captcha": this.captcha,
                    "email": this.mail,
                    "spread_spid": 0
                }).then(response => { 
                    console.log(response);
                    if(response.code == 200) {
                        localStorage.setItem('LOGIN_STATUS_TOKEN', response.data.token)
                        localStorage.setItem('USER_INFO', JSON.stringify(response.data))
                        this.getCartCount();
                        this.getWishlistCount();
                        location.reload() 
                        this.$emit('loginClose', false)
                    }else{
                        this.messagePlugin.error(response.message)
                    }
                })
            }else{
                this.http.post('/api/front/login/mobile',{
                    "captcha": this.captcha,
                    "phone": this.phone,
                    "spread_spid": 0
                }).then(response => { 
                    if(response.code == 200) {
                        localStorage.setItem('LOGIN_STATUS_TOKEN', response.data.token)
                        localStorage.setItem('USER_INFO', JSON.stringify(response.data))
                        
                        this.getCartCount();
                        this.getWishlistCount();
                        location.reload() 
                        this.$emit('loginClose', false)
                    }else{
                        this.messagePlugin.error(response.message)
                    }
                })
            }
        },
        getSendCode(){
            let code = 0;
            if(this.loginType == 'mail'){
                if(this.mail == ''){
                    this.messagePlugin.error(this.$t('inputMail'))
                    return
                }
                this.http.post('/api/front/sendEmailCode',"email="+ this.mail).then(response => { 
                    console.log(response);
                    this.messagePlugin.info(response.message)
                    code = response.code;
                })
            }else{
                if(this.phone == ''){
                    this.messagePlugin.error(this.$t('inputPhone'))
                    return
                }
                this.http.post('/api/front/sendCode',"phone="+ this.phone).then(response => { 
                    console.log(response);
                    this.messagePlugin.info(response.message)
                    code = response.code;
                })
            }
            let codeSetInterval = setInterval(()=>{
                if(code != 0){
                    clearInterval(codeSetInterval);
                    if(code == 200){
                        this.captchaTime--;
                        let captchaTime = setInterval(()=>{
                            if(this.captchaTime < 1 || this.captchaTime == 60){
                                this.captchaTime = 60;
                                clearInterval(captchaTime);
                            }
                            if(this.captchaTime != 60){
                                this.captchaTime--;
                            }
                        },1000)
                    }
                }
            },200)
        }


    }
}
</script>
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped> 
a {
     text-decoration: underline;
     cursor: pointer;
 }

 .login {
     text-align: left;

 }

 .input_title {
     padding: 10px 0;
     font-size: 16px;
 }

 .e-button {
    background-color: #000000 ;
     border-radius: 5px;
     height: 40px;
     margin: 20px 0;
 }

 ::v-deep {
     .t-input {
         height: 40px;
         border: 1px solid;
     }
     .t-tabs__nav-item.t-is-active{
        color: #000000;
     }
     .t-tabs__bar{
        background-color: #000000 !important;
     }
     .t-button--variant-base.t-button--theme-primary:not(.t-is-disabled):not(.t-button--ghost) {
         border-color: #000000 !important;
         background-color: #000000 !important;
         --ripple-color: #000000 !important;
     }

     .t-button--variant-base.t-button--theme-primary:hover {
         border-color: #000000 !important;
         background-color: #000000 !important;
     }

     .t-button--variant-base.t-button--theme-primary{
        background-color: #000000 !important;
        border-color: #000000 !important;
     }

     .t-checkbox.t-is-checked .t-checkbox__input {
         border-color: #000000 !important;
         background-color: #000000 !important;

     }

     .t-input:hover {
         border-color: #000000 !important;
     }

     .t-input--focused {
         border-color: #000000 !important;
         box-shadow: none;
     }
 }
.login_dlg{
    display: none;
}
 @media screen and (max-width: 768px) {
     /* 手机屏幕样式 */
    
    .login_drawer{
        display: inline !important;
        z-index: 999999;
    }
 }

 @media screen and (min-width: 769px) {
     /* 平板或电脑屏幕样式 */
     .login_dialog{
        display: inline;
    }
 }</style>
  