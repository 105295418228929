
import cfg from '../assets/config'
import axios from 'axios';
function getToken(headers){
    console.log(localStorage.getItem('LOGIN_STATUS_TOKEN'))
    if(localStorage.getItem('LOGIN_STATUS_TOKEN')){
        headers.headers['Authori-zation'] = localStorage.getItem('LOGIN_STATUS_TOKEN')
    }
}
export default{  
    get : (url)=>{   
        return new Promise((resolve) => { 
            console.log(url+'进入get')
            let headers = {
                    headers: {
                    }
                }
            getToken(headers)
            console.log(headers);
            axios.get(cfg.apihost + url,headers ).then((data) => {
                resolve(data.data);
            }, (err) => { 
                resolve({
                    err: err.status,
                    message: "網絡錯誤"
                });
            });
        })
    },
    post : (url,data)=>{  
        return new Promise((resolve) => { 
            console.log(url+'进入post')
            let headers = {
                headers: {
                }
            }
            getToken(headers)
            console.log(headers);
            axios.post(cfg.apihost + url,data,headers).then((val) => {
                resolve(val.data);
            }, (err) => { 
                resolve({
                    err: err.status,
                    message: "網絡錯誤"
                });
            });
        })
    }
}