<template>
    <div class="search">
        <span class="mobile">
            <icon-font style="font-size: 30px;line-height: 50px; " name="search" @click="searchVisible = !searchVisible" />
        </span>
        <div class="pc">
            <t-button variant="text" style="padding: 0;width: 50px;" @click="clicksearch">
                <icon-font style="font-size: 25px;" name="search"  />
            </t-button>
            <span style="width: calc(100% - 50px);"  @click="searchVisible = !searchVisible">
                <t-input clearable v-model="searchValue" :placeholder="$t('search')" style="border: 0;height: 30px;width: 100%;" />
            </span>
        </div>
        <t-drawer :visible.sync="searchVisible" :header="false" :footer="false" size="500px" placement="top">
            <div class="search_drawer_header">
                <img height="40" style="padding-left: 30px;" src="@/assets/logo-w.png" alt="logo" />
                <icon-font style="float: right;font-size: 30px;padding-top: 5px;" name="close" @click="searchVisible = !searchVisible"/>
            </div>
            <div class="search_drawer_input">
                <t-button variant="text" style="padding: 0;width: 50px;" @click="clicksearch">
                    <icon-font style="font-size: 25px;" name="search"  />
                </t-button>
                <t-input clearable v-model="searchValue" :placeholder="$t('search')" style="border: 0;height: 30px;width: 100%;" @blur="searchBlur" @keyup="clicksearch" @clear="inputClear" />
            </div>
            <div class="search_drawer_list">
                <div class="list">
                    <ul>
                        <li v-for="(item,index) of searchData" :key="index">
                            <div class="searchli" @click="clickitem(item.id)">{{ item.storeName }}</div>
                            <icon-font style="float: right;font-size: 20px;line-height: inherit;" name="chevron-right"/>
                        </li>
                    </ul>
                    <h2 style="text-align: center;" v-if="searchHistory.length > 0">
                        {{ $t('components_header_menu.searchHistory') }}
                    </h2>
                    <ul>
                        <li v-for="(item,index) of searchHistory" :key="index">
                            <div class="searchli" @click="searchValue = item;clicksearch();searchBlur()">{{ item }}</div>
                            <icon-font style="float: right;cursor:pointer" name="close" @click="delSearchHistory(index)"/></li>
                    </ul>
                </div>  
            </div>
        </t-drawer>
    </div>
</template>
<script>
    export default {
        name: 'SearchPage',
        data() {
            return { 
                searchVisible : false,
                refuseSearch : false,
                searchValue : '',
                searchData : [],
                searchHistory : [],
            };
        },
        created(){
            if(localStorage.getItem('searchHistory')){
                this.searchHistory = JSON.parse(localStorage.getItem('searchHistory'));
            }
        },
        mounted(){  
            	       
        },
        props: { 
        }, 
        components: {  
        },
        methods:{
            clickitem(id){
                this.searchVisible = false;
                this.$router.push({ path: '/shopping/'+id });
            },
            clicksearch(){
                console.log('进入');
                this.refuseSearch = false;
                if (this.searchValue.trim() == '') {
                    this.refuseSearch = true;
                    this.inputClear();
                    return
                } 
                this.http.get('/api/front/products?keyword='+this.searchValue+'&page=1&limit=10').then(response => { 
                    if(response.code == 200 && !this.refuseSearch) {
                        this.searchData = response.data.list;
                    }
                })
            },
            searchBlur(){
                if (this.searchValue.trim() == '') {
                    this.searchData = [];
                    return
                }
                let searchHistoryIndex = this.searchHistory.findIndex(s=> s == this.searchValue)
                if (searchHistoryIndex != -1) {
                    this.delSearchHistory(searchHistoryIndex)
                }
                this.searchHistory.unshift(this.searchValue);
                this.searchHistory.splice(10,1);
                localStorage.setItem('searchHistory',JSON.stringify(this.searchHistory))
            },
            delSearchHistory(index){
                this.$delete(this.searchHistory,index)
                localStorage.setItem('searchHistory',JSON.stringify(this.searchHistory))
            },
            inputClear(){
                this.searchData = [];
            }
        }

    };
</script>
  
<style lang="less" scoped>
    .searchli{
        width: calc(100% - 20px);
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .list{
        overflow: auto;
        ul{
            padding: 0;
            li{
                text-align: left;
                list-style-type: none; /* 去掉圆点 */
                font-size: 15px;
                padding: 10px;
                cursor:pointer
            }
        } 
    }
    ::v-deep{
        ::-webkit-scrollbar {  
            width: 20px !important;
        } 
        .t-input.t-is-disabled{
            background-color : #fff;
            border : 0;
        }
 
        .t-input:hover{
            border-color: #fff;
        } 
        .t-input{
            border-color: #fff;
        } 
        .t-input--focused{
            border-color: #fff;
            box-shadow:none;
        }
       
        .t-radio.t-is-checked .t-radio__input{
            border-color: #000;
        }
        .t-tabs__nav-item.t-is-active{
            color: #000;
        }
        .t-tabs__bar{
            background-color: #000000;
        }
        .t-radio__input::after{
            background-color: #000000;
        }

    }
    .search_drawer_header{
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        padding: 5px 15px;
        background-color: #fff !important;
        z-index: 9;
        text-align: center;
    }
    .search_drawer_input{
        position: fixed;
        left: 0;
        right: 0;
        top: 60px;
        background-color: #fff !important;
        z-index: 9;

        border: 1px solid;
        border-radius: 5px;
        display: flex;
        margin: 0 30% 0 30%;
    }
    .search_drawer_list{
        padding: 0 30%;
        padding-top: 90px !important;

    }

    @media screen and (max-width: 768px) {
    /* 手机屏幕样式 */ 
        .mobile{
            display: block;
        }
        .pc{
            display: none;
        }
        .search{
            display: inline-block;
        }
        .search_drawer_input{ 
            margin: 0 10%;
        }
        .search_drawer_list{
            padding: 0 10%;
        }
    }
        
    @media screen and (min-width: 769px) {
        /* 平板或电脑屏幕样式 */ 
        .mobile{
            display: none;
        }
        .pc{
            border: 1px solid;
            border-radius: 5px;
            width: 100%;
            display: flex;
            margin-top: 10px;
        }
    }
</style>
 