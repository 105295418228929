<template>
    <div class="header">
        <Menu class="pc" :MenuData="MenuData" />
        <SideMenu class="phone" :MenuData="MenuData"/>
    </div>
</template>
<script>
import Menu from "./Menu/Menu.vue";
import SideMenu from "./SideMenu/SideMenu.vue";
export default {
    name: 'HeaderPage',
    data() {
      return{
        MenuData : []
      }
    },
    components:{
        Menu,
        SideMenu,
    },
    created() {
      this.http.get('/api/front/category').then(response => {
        console.log(response);
        if(response.code == 200) {
          let data = response.data.sort(function(a, b){return a.sort - b.sort});
          data.forEach(element => {
            element.child.sort(function(a, b){return a.sort - b.sort});
          });
          this.MenuData = data; 
        }
      })
    },
    mounted() {
    } 
};
</script>
  
<style scoped>
@media screen and (max-width: 768px) {
    /* 手机屏幕样式 */ 
    .pc{
      display: none;
    } 
  }
    
  @media screen and (min-width: 769px) {
    /* 平板或电脑屏幕样式 */ 
    .phone{
      display: none;
    } 
  }
  @media screen and (min-width: 1200px) {
    /* 平板或电脑屏幕样式 */ 
  
  }
   
</style>