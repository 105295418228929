<template>
    <div class="SideMenu">
        <!-- <GlobalPos/> -->
        <div class="menu">
            <div class="submenu">
                <icon-font class="header_icon" name="view-list" @click="visible = !visible" />
                <Search/>
                <!-- <icon-font class="header_icon" name="search" /> -->
            </div>
            <div class="logo">
                <router-link :to="{name:'Home'}"> 
                    <img height="50" src="@/assets/logo-w.png" alt="logo" />
                </router-link>
            </div>
            <div class="operations">
                <router-link :to="{name:'Wishlist'}"> 
                    <t-badge :count="observable.WishlistCount" :offset="[25, 33]" color="#000" size="small">
                            <icon-font class="header_icon" name="heart" />
                    </t-badge>
                </router-link>
                <router-link :to="{name:'Checkout'}"> 
                    <t-badge :count="observable.CartCount" :offset="[20, 33]" color="#000" size="small">
                            <icon-font class="header_icon" name="shop" />
                    </t-badge>
                </router-link> 
            </div>
        </div>
        <t-drawer :visible.sync="visible" :header="false" :footer="false" size="100%" placement="left">
            <div class="header">
                <img height="40" style="margin-top: -10px;" src="@/assets/logo-w.png" alt="logo" />
                <icon-font style="float: right;font-size: 30px;" name="close" @click="visible = !visible"/>
            </div>
            <t-tabs :value="0">
                <t-tab-panel :value="index" :label="(item.name + index)" v-for="(item,index) of MenuData" :key="index" :destroyOnHide="false">
                    <ul class="list">
                        <li @click="clicklist(c.id)" v-for="(c,cindex) of item.child" :key="cindex">{{ c.name }}<icon-font style="float: right;" name="chevron-right"/></li>
                    </ul>
                </t-tab-panel>
            </t-tabs> 
            
            <h2>
                {{ $t('components_header_menu.myDetails') }}
            </h2>
            <div v-if="!isuser">
                <t-button class="login_button" block @click="observable.loginVisible = true;visible = false">{{ $t('login') }}</t-button>
            </div>
            <div v-if="isuser">
                <ul>
                    <router-link :to="{name:'Details'}"> 
                        <li @click="visible = false">{{ $t('details') }}<icon-font style="float: right;" name="chevron-right"/></li>
                    </router-link>
                    <router-link :to="{name:'Orders'}"> 
                        <li @click="visible = false">{{ $t('orders') }}<icon-font style="float: right;" name="chevron-right"/></li>
                    </router-link>
                    <router-link :to="{name:'Address'}"> 
                        <li @click="visible = false">{{ $t('address') }}<icon-font style="float: right;" name="chevron-right"/></li>
                    </router-link>
                </ul> 
                <t-popconfirm :confirmBtn="$t('confirmBtn')" :cancelBtn="$t('cancel')" :visible="logoutVisible" theme="default" :content="$t('components_header_menu.isLogout')" @visible-change="logout">
                    <t-button class="login_button" block>{{ $t('logout') }}</t-button>
                </t-popconfirm>
            </div>
            <h2>
                {{ $t('components_header_menu.language') }}
            </h2>
            <ul>
                <li @click="visible1=true">{{ $t('components_header_menu.language') }} :  {{ $t('languageName') }} <icon-font style="float: right;" name="chevron-right"/></li>
                <!-- <li>目的地 : 中国大陆<icon-font style="float: right;" name="chevron-right"/></li> -->
            </ul>
        </t-drawer>
        
        <t-drawer :visible.sync="visible1" :header="$t('components_header_menu.language')" :footer="false" size="100%" placement="right" :closeBtn="true">
            <ul style="margin: 0;">
                <li @click="onLanguageChange('zh')">中文</li>
                <li @click="onLanguageChange('ru')">русский язык</li>
            </ul>
        </t-drawer>
    </div>
</template>
<script>
// import GlobalPos from "../GlobalPos/GlobalPos.vue";
import Search from "../Menu/Search/Search.vue";
export default {
    name: 'SideMenuPage',
    data() {
        return {
            visible: false,
            visible1:false,
            isuser : false,
            logoutVisible : false,
            username : ''
        };
    },
    mounted() {
        console.log("SideMenuPage mounted");
        if(!this.getTokenState(false)){
            this.isuser = true;
        }
    },
    props: {
        MenuData: {
            type: Array,
            default: () => {}
        }
    },
    components: {
        // GlobalPos,
        Search,
    },
    methods: {
        onLanguageChange(type){
            localStorage.setItem('language',type)
            location.reload() 
        },
        logout(val, context = {}){
            console.log(val, context);
            if (context && context.trigger === 'confirm') {
                this.logoutVisible = false;
                this.http.get('/api/front/logout').then(response => {
                    if(response.code == 200) {
                        localStorage.clear()
                        this.$router.push({name:'/'});
                        location.reload() 
                    }
                })
                
            } else {
                this.logoutVisible = val;
            }
            
        },
        clicklist(id){
            console.log(id);
            this.visible = false;
            this.$router.push({name:'PortalSlicesListing',query: {id:id}});
        },
        clickItem(e){
            this.visible = false;
            this.visible1 = false;
            console.log(e);
        }
    }

};
</script>
  
<style lang="less" scoped> 

    ::v-deep{
        .t-tabs__header{
            position: fixed;
            top: 40px;
            left: 10px;
            right: 10px;
            background-color: #fff;
            z-index: 9;
        }
        .t-tabs__nav-item.t-is-active{
            color: #000;
        }
        .t-tabs__bar{
            background-color: #000;
        }
    } 
    .header_icon {
        font-size: 30px;
        line-height: 50px; 
    }
    .header_icon:not(:last-child) { 
        padding-right: 15px;
    }
    .header{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        padding: 10px;
        background-color: #fff;
        text-align: center;
        z-index: 9;
    }
    .items{
        padding-top: 40px;
    }
    .list{
        padding-top: 60px;
    }

    ul{
        padding: 0;
        li{
            text-align: left;
            list-style-type: none; /* 去掉圆点 */
            font-size: 15px;
            padding: 10px;
        }
    }
    .login_button{
        padding: 20px !important;
        margin-bottom: 10px !important;
        background-color: #000 !important;
        border-radius: 8px;  
        border: 0;
    }
    // .register_button{
    //     padding: 20px !important;
    //     color: #000 !important;
    //     background-color: #fff !important;
    //     border: 1px solid !important;
    //     border-radius: 8px;  
    // }
    .SideMenu {
        text-align: left;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        background-color: #fff;
        border-bottom: 1px solid #e5e5e5;
        z-index: 999;
        padding-left: 20px;
        padding-right: 20px;
    }

    .menu {
        display: flex;
        height: 50px;
        .submenu {
            width: calc(50% - 60px);
            text-align: left;
        }
        .logo {
            width: 120px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .operations {
            width: calc(50% - 60px);
            text-align: end;
        }
    }
 </style>
 