<template>
    <div class="menu">
        <!-- <GlobalPos/> -->
        <div class="menu1">
            <div class="submenu">
                <t-button class="menubtn" v-for="(item,index) of MenuData" :key="index" :class="{selectmenu1: menu1 == index}" theme="default" variant="text" @click="clickMenu1(index)">
                    {{ item.name }}
                </t-button>
                <!-- <t-button class="menubtn" :class="{selectmenu1: menu1 == 2}" theme="default" variant="text" @click="clickMenu1(2)">
                    手表
                </t-button>  -->
            </div>
            <div class="logo">
                <router-link :to="{name:'Home'}"> 
                    <img height="50" src="@/assets/logo-w.png" alt="logo" />
                </router-link>
            </div>
            <div class="operations">

                <t-popup v-model="languageVisible" placement="bottom-right" trigger="click">
                    <template #content> 
                        <div style="width: 300px;">
                            <t-tabs v-model="tabsValue">
                                <t-tab-panel value="1" :label="$t('components_header_menu.language')" :destroyOnHide="false">
                                    <div style="height: 300px;overflow:auto">
                                        <t-radio-group v-model="languageValue"  @change="onLanguageChange">
                                            <t-radio value="zh" class="language_radio">中文</t-radio>
                                            <t-radio value="ru" class="language_radio">русский язык</t-radio>
                                        </t-radio-group>
                                    </div>
                                </t-tab-panel>
                                <!-- <t-tab-panel value="2" label="目的地" :destroyOnHide="false">
                                    <div>
                                        选择一个国家或地区。这将影响您的结算货币、商品库存、价格与配送选项。
                                    </div>
                                    <div style="height: 300px;overflow:auto">
                                        <t-radio-group v-model="languageValue">
                                            <t-radio value="1" class="language_radio">中国</t-radio>
                                            <t-radio value="2" class="language_radio">俄罗斯</t-radio>
                                            <t-radio value="3" class="language_radio">美国</t-radio>
                                        </t-radio-group>
                                    </div>
                                </t-tab-panel>  -->
                            </t-tabs>
                        </div>
                    </template>
                    <t-button class="header_button" variant="text" shape="square">
                        <icon-font class="header_icon" name="internet" />
                    </t-button>
                </t-popup>

                <t-popup v-model="isuser" placement="bottom-right" trigger="click">
                    <template #content>
                        <div class="user_popup">
                            {{ username }}
                        </div>
                        <div>
                            <ul> 
                                <router-link :to="{name:'Details'}"> 
                                    <li @click="isuser = false">{{ $t('details') }}</li>
                                </router-link>
                                <router-link :to="{name:'Orders'}"> 
                                    <li @click="isuser = false">{{ $t('orders') }}</li>
                                </router-link>
                                <router-link :to="{name:'Address'}"> 
                                    <li @click="isuser = false">{{ $t('address') }}</li>
                                </router-link>
                            </ul>
                        </div>
                        
                        <div style="text-align: right;padding: 10px;">
                            <t-popconfirm :confirmBtn="$t('confirmBtn')" :cancelBtn="$t('cancel')" :visible="logoutVisible" theme="default" :content="$t('components_header_menu.isLogout')" placement="bottom" @visible-change="logout">
                                <t-button style="background-color: #000;width: 45%;">{{ $t('logout') }}</t-button>
                            </t-popconfirm>
                        </div>
                    </template>
                </t-popup>
                
                <t-button class="header_button" variant="text" shape="square" @click="clickuser">
                    <icon-font class="header_icon" name="user" />
                </t-button>
                <router-link :to="{name:'Wishlist'}"> 
                    <t-badge :count="observable.WishlistCount" :offset="[25, 23]" color="#000" size="small">
                        <t-button class="header_button" variant="text" shape="square">
                            <icon-font class="header_icon" name="heart" />
                        </t-button>
                    </t-badge>
                </router-link>
                <router-link :to="{name:'Checkout'}"> 
                    <t-badge :count="observable.CartCount" :offset="[25, 23]" color="#000" size="small">
                        <t-button class="header_button" variant="text" shape="square">
                            <icon-font class="header_icon" name="shop" />
                        </t-button>
                    </t-badge>
                </router-link>
            </div> 
        </div>
        <div class="menu2" v-if="MenuData && MenuData[menu1]">
            <div class="submenu"> 
                <t-button class="menubtn" v-for="(item,index) of MenuData[menu1].child" :key="index" :class="{selectmenu2: currmenu == menu1+'-'+index}" theme="default" variant="text" @click="clickMenu2(item.id,index)" >
                    {{ item.name }}
                </t-button> 
            </div>
            <Search/>
        </div>
        <!-- <div class="menu3" :class="{selectmenu3: menu2 == 1}" @mouseover="mouseoverMenu2(1)" @mouseout="mouseoverMenu2(0)">
            <div class="left">
                <t-row>
                    <t-col :xs="12" :sm="4">
                        <ul>
                            <li>折扣狂欢</li>
                            <li>折扣服装</li>
                            <li>折扣服装</li>
                            <li>折扣鞋</li>
                            <li>折扣连衣裙</li>
                            <li>折扣服装</li>
                            <li>折扣服装</li>
                            <li>折扣服装</li>
                            <li>折扣服装</li>
                        </ul>
                    </t-col>
                    <t-col :xs="12" :sm="4">
                        <ul>
                            <li>折扣狂欢</li>
                            <li>折扣服装</li>
                            <li>折扣服装</li>
                            <li>折扣鞋</li>
                            <li>折扣连衣裙</li>
                            <li>折扣服装</li>
                            <li>折扣服装</li>
                            <li>折扣服装</li>
                            <li>折扣服装</li>
                        </ul>
                    </t-col>
                    <t-col :xs="12" :sm="4">
                        <ul>
                            <li>折扣狂欢</li>
                            <li>折扣服装</li>
                            <li>折扣服装</li>
                            <li>折扣鞋</li>
                            <li>折扣连衣裙</li>
                            <li>折扣服装</li>
                            <li>折扣服装</li>
                            <li>折扣服装</li>
                            <li>折扣服装</li>
                        </ul>
                    </t-col>
                </t-row> 
                
            </div>
            <div class="right">
                <div>
                    <img src="" alt="">
                    <div>
                        <h4>重磅来袭</h4>
                        假日频道 泳装上演<br>
                        <a >去逛逛</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="menu3" :class="{selectmenu3: menu2 == 2}" @mouseover="mouseoverMenu2(2)" @mouseout="mouseoverMenu2(0)">
            <div class="left">
                <t-row>
                    <t-col :xs="12" :sm="4">
                        <ul>
                            <li>折扣狂欢</li>
                            <li>折扣服装</li>
                            <li>折扣服装</li>
                            <li>折扣鞋</li>
                            <li>折扣连衣裙</li>
                            <li>折扣服装</li>
                            <li>折扣服装</li>
                            <li>折扣服装</li>
                            <li>折扣服装</li>
                        </ul>
                    </t-col>
                    <t-col :xs="12" :sm="4">
                        <ul>
                            <li>折扣狂欢</li>
                            <li>折扣服装</li>
                            <li>折扣服装</li>
                            <li>折扣鞋</li>
                            <li>折扣连衣裙</li>
                            <li>折扣服装</li>
                            <li>折扣服装</li>
                            <li>折扣服装</li>
                            <li>折扣服装</li>
                        </ul>
                    </t-col>
                    <t-col :xs="12" :sm="4">
                        <ul>
                            <li>折扣狂欢</li>
                            <li>折扣服装</li>
                            <li>折扣服装</li>
                            <li>折扣鞋</li>
                            <li>折扣连衣裙</li>
                            <li>折扣服装</li>
                            <li>折扣服装</li>
                            <li>折扣服装</li>
                            <li>折扣服装</li>
                        </ul>
                    </t-col>
                </t-row> 
                
            </div>
            <div class="right">
                <div>
                    <img src="" alt="">
                    <div>
                        <h4>重磅来袭</h4>
                        假日频道 泳装上演<br>
                        <a >去逛逛</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="menu3" :class="{selectmenu3: menu2 == 3}" @mouseover="mouseoverMenu2(3)" @mouseout="mouseoverMenu2(0)">
            <div class="left">
                <t-row>
                    <t-col :xs="12" :sm="4">
                        <ul>
                            <li>折扣狂欢</li>
                            <li>折扣服装</li>
                            <li>折扣服装</li>
                            <li>折扣鞋</li>
                            <li>折扣连衣裙</li>
                            <li>折扣服装</li>
                            <li>折扣服装</li>
                            <li>折扣服装</li>
                            <li>折扣服装</li>
                        </ul>
                    </t-col>
                    <t-col :xs="12" :sm="4">
                        <ul>
                            <li>折扣狂欢</li>
                            <li>折扣服装</li>
                            <li>折扣服装</li>
                            <li>折扣鞋</li>
                            <li>折扣连衣裙</li>
                            <li>折扣服装</li>
                            <li>折扣服装</li>
                            <li>折扣服装</li>
                            <li>折扣服装</li>
                        </ul>
                    </t-col>
                    <t-col :xs="12" :sm="4">
                        <ul>
                            <li>折扣狂欢</li>
                            <li>折扣服装</li>
                            <li>折扣服装</li>
                            <li>折扣鞋</li>
                            <li>折扣连衣裙</li>
                            <li>折扣服装</li>
                            <li>折扣服装</li>
                            <li>折扣服装</li>
                            <li>折扣服装</li>
                        </ul>
                    </t-col>
                </t-row> 
                
            </div>
            <div class="right">
                <div>
                    <img src="" alt="">
                    <div>
                        <h4>重磅来袭</h4>
                        假日频道 泳装上演<br>
                        <a >去逛逛</a>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>
<script>
// import GlobalPos from "../GlobalPos/GlobalPos.vue";
import Search from "./Search/Search.vue";
import observable from "@/services/observable.js";
    export default {
        name: 'MenuPage',
        data() {
            return { 
                username : '',
                logoutVisible : false,
                languageVisible : false,
                isuser : false,
                languageValue : 'ru',
                currmenu : '0-99',
                menu1 : 0,
                menu2 : 99,
                tabsValue : '1'
            };
        }, 
        mounted(){ 
            console.log("MenuMounted");
            if(localStorage.getItem('language')){
                this.languageValue = localStorage.getItem('language')
            }
        },
        props: {
            MenuData: {
                type: Array,
                default: () => {}
            }
        }, 
        components: { 
            // GlobalPos,
            Search,
        },
        methods:{
            onLanguageChange(e){
                localStorage.setItem('language',e)
                location.reload() 
            },
            logout(val, context = {}){
                console.log(val, context);
                if (context && context.trigger === 'confirm') {
                    this.isuser = false;
                    this.logoutVisible = false;
                    this.http.get('/api/front/logout').then(response => {
                        if(response.code == 200) {
                            localStorage.clear()
                            this.$router.push({name:'/'});
                            location.reload() 
                        }
                    })
                } else {
                    this.logoutVisible = val;
                }
            },
            clickuser(){
                if(localStorage.getItem('LOGIN_STATUS_TOKEN')){
                    observable.loginVisible=false;
                    this.isuser = true;
                    this.username = JSON.parse(localStorage.getItem('USER_INFO')).nikeName;
                }else{
                    observable.loginVisible=true;
                    this.isuser = false;
                }
            },
            clickMenu1(index){
                console.log(index);
                this.menu1 = index;
            },
            clickMenu2(id,index){
                this.menu2 = index;
                this.currmenu = this.menu1+'-'+this.menu2;
                this.$router.push({name:'PortalSlicesListing',query: {id:id}});
            },
            mouseoverMenu2(index){
                this.menu2 = index;
            }
        },
        watch: {
            MenuData() {
                for(let i = 0; i < this.MenuData.length; i++) {
                    for(let j = 0; j < this.MenuData[i].child.length;j++) {
                        if(this.$route.query.id == this.MenuData[i].child[j].id+'') {
                            this.menu1 = i;
                            this.menu2 = j;
                            this.currmenu = i+'-'+j;
                            return
                        }
                    }
                }
            }
        }

    };
</script>
  
<style lang="less" scoped>
    
    .language_radio{
        width: 100%;
        border-bottom: 1px solid #cecece;
        padding: 10px; 
    }
    .user_popup{
        text-align: left;
        font-size: 20px;
        width: 200px;
        padding: 12px;
        border-bottom: 1px solid #e5e5e5;
    }

    .list{
        max-height:350px;
        overflow: auto;
    }
    ::v-deep{
        ::-webkit-scrollbar {  
            width: 20px !important;
        } 
        .t-input.t-is-disabled{
            background-color : #fff;
            border : 0;
        }
 
        .t-input:hover{
            border-color: #fff;
        } 
        .t-input{
            border-color: #fff;
        } 
        .t-input--focused{
            border-color: #fff;
            box-shadow:none;
        }
       
        .t-radio.t-is-checked .t-radio__input{
            border-color: #000;
        }
        .t-tabs__nav-item.t-is-active{
            color: #000;
        }
        .t-tabs__bar{
            background-color: #000000;
        }
        .t-radio__input::after{
            background-color: #000000;
        }
        .t-button--variant-base.t-button--theme-primary:hover{
        background-color: #000;
        }
        .t-button--variant-base.t-button--theme-primary{
        border-color: #000;
        }
        .t-button--variant-base.t-button--theme-primary:hover{
            border-color: #000;
        } 
    }
    ul{
        padding: 0;
        li{
            text-align: left;
            list-style-type: none; /* 去掉圆点 */
            font-size: 15px;
            padding: 10px;
        }
    }
    .header_button{
        margin-right: 15px !important;
        .header_icon {
            font-size: 25px !important;
        }
    }
    
    .selectmenu1{
        font-weight: 900;
    }
    .selectmenu2{
        font-weight: 900;
    }
    .selectmenu3{
        display: flex !important;
    }
    .menubtn{
        padding-left: 10px;
        font-size: 15px;
    }
    .menu{
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        background-color: #fff;
        border-bottom: 1px solid #e5e5e5;
        z-index: 999;
        padding-left: 20px;
        padding-right: 20px;
    } 

    .menu1{
        display: flex;
        height: 50px;
        .submenu{
            width: calc(50% - 75px);
            text-align: left;
            line-height: 50px;
        }
        .logo{
            width: 150px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .operations{
            width: calc(50% - 75px);
            text-align: end;
            line-height: 50px;
        }
    }
    .menu2{
        display: flex;
        height: 50px;
        .submenu{
            width: calc(100% - 250px);
            text-align: left;
            line-height: 50px;
            .menubtn{
                height: 100%;
            }
        }
        .search{
            width: 250px;
            text-align: end; 
            input{ 
                outline: none;  //点击不显示黑边框
            }
        }
    }

    .menu3{
        display: none; 
        .left{
            width: 70%;
        }
        .right{
            width: 30%;
        }
    } 
</style>
 