<template>
    <div class="e-phone"> 
        <!-- <t-select v-model="encode" :options="optionsData" placeholder="请选择" style="width: 100px;padding-right: 5px;" /> -->
        <t-input v-model="phone" :placeholder="$t('pleaseInput')+$t('phone')" style="width: 100%;" @change="inputBlur"/>
    </div>
  </template>
  
  <script>
  export default {
    name: 'EPhonePage',
    data(){
          return{ 
            optionsData : [
              { "label": "+86", "value": "+86" },
              { "label": "+1", "value": "+1" },
              { "label": "+91", "value": "+91" },
              { "label": "+81", "value": "+81" },
              { "label": "+49", "value": "+49" },
              { "label": "+44", "value": "+44" },
              { "label": "+33", "value": "+33" },
              { "label": "+1", "value": "+1" },
              { "label": "+61", "value": "+61" },
              { "label": "+55", "value": "+55" },
              { "label": "+7", "value": "+7" },
              { "label": "+82", "value": "+82" },
              { "label": "+34", "value": "+34" },
              { "label": "+39", "value": "+39" },
              { "label": "+31", "value": "+31" }
            ],
            encode : "+86",
            phone : "",
          }
    },
    props: { 
      value: {
          type: String,
          default: ''
      }
    },
    components:{
    },
    methods : {
      inputBlur(e){
        this.$emit('update:value', e)
      }
    },
    watch:{  // 监听
      value:{
        handler (newVal) {
            this.phone = newVal;
        },
        deep: true,
        immediate: true,
      }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang="less" scoped> 
  .e-phone{
    width: 100%;
    display: flex;
  }
  ::v-deep{
     .t-input {
         height: 40px;
         border: 1px solid #e5e5e5;
     }
    .t-input:hover {
         border-color: #000000;
     }

     .t-input--focused {
         border-color: #000000;
         box-shadow: none;
     }
  }
  @media screen and (max-width: 768px) {
    /* 手机屏幕样式 */ 
  }
   
  @media screen and (min-width: 769px) {
    /* 平板或电脑屏幕样式 */ 
  }
  </style>
  