import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

// 导入语言文件
import ru from './assets/locales/ru.json';
import zh from './assets/locales/zh.json';

let locale = 'ru';
if(localStorage.getItem('language')){
  locale = localStorage.getItem('language')
}

const i18n = new VueI18n({
  locale: locale, // 默认语言
  fallbackLocale: 'zh', // 当所请求的语言不存在时，使用的备用语言
  messages: {
    ru, // 俄语语言文件
    zh, // 中文语言文件
  },
});

export default i18n;